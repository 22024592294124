const API_BASE_URL = 'https://doctorcrm.stetho.hindustanlab.com';
export class APIEndPoint {
  public static LOGIN: APIDef = {
    method: 'POST',
    api: () => `hw/login/crm`,
  };
  public static VERIFY_LOGIN_OTP: APIDef = {
    method: 'POST',
    api: () => `hw/login/verify/otp`,
  };

  public static VERIFY_ADDMEMBER_OTP: APIDef = {
    method: 'POST',
    api: () => `hw/family/add/otp/verify`,
    noNeedAuthToken: true,
  };

  public static USER_DETAILS: APIDef = {
    method: 'POST',
    api: () => `hw/user/UserDetails`,
  };
  public static DOCTOR_DETAILS: APIDef = {
    method: 'POST',
    api: () => `hw/doctorDetails`,
  };

  public static GET_CALL_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/doctor-dashboard`,
  };

  public static GET_DOCTOR_DETAIL_STEAM: APIDef = {
    method: 'POST',
    api: () => `hw/doctorDetailsteam`,
  };

  public static GET_CITY_NAME: APIDef = {
    method: 'POST',
    api: () => `hw/family/getCity`,
  };

  public static GET_CONSULTATION: APIDef = {
    method: 'GET',
    api: () => `local/api/consultation.json`,
  };
  public static GET_PATIENT_UPLOADED_DOC: APIDef = {
    method: 'GET',
    api: () => `local/api/patient-uploaded-doc.json`,
  };


  public static DOCTOR_OFFLINE: APIDef = {
    method: 'POST',
    api: () => `vs/doctor/offline`,
  };
  public static DOCTOR_ONLINE: APIDef = {
    method: 'POST',
    api: () => `vs/doctor/online`,
  };
  public static MY_CALL: APIDef = {
    method: 'POST',
    api: () => `vs/doctor/myCall`,
  };
  public static DOCTOR_PING: APIDef = {
    method: 'POST',
    api: () => `vs/doctor/ping`,
  };
  public static DOCTOR_ACTIVE: APIDef = {
    method: 'POST',
    api: () => `vs/doctor/active`,
  };
  public static CALL_WAITING: APIDef = {
    method: 'POST',
    api: () => `hw/callWaiting`,
  };
  //<====================================> DEFAULT MIDDLE PAGE API <====================================>

  public static GET_USER_VITALS: APIDef = {
    method: 'POST',
    api: () => `hw/CustomerVitals`,
  };
  public static CREATE_USER_VITALS: APIDef = {
    method: 'POST',
    api: () => `hw/updateCustomerUserVitals`,
  };
  public static CREATE_SUMMARY_NOTES: APIDef = {
    method: 'POST',
    api: () => `hw/updateSummaryNotes`,
  };

  public static GET_SUMMARY_NOTES: APIDef = {
    method: 'POST',
    api: () => `hw/summary-notes`,
  };

  public static GET_DEFAULT_ALLERGY: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultAllergy`,
  };
  public static GET_DEFAULT_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultMedicalHistory`,
  };
  public static GET_DEFAULT_MEDICINES: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultMedicines`,
  };
  public static GET_DEFAULT_PROVISIONAL_DIAGNOSIS: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultDiagnosis`,
  };
  public static GET_DEFAULT_SYMPTOMS: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultSymptoms`,
  };
  public static GET_DEFAULT_INSTRUCTIONS: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultInstruction`,
  };
  public static GET_DEFAULT_TEST_RECOMMENDATION: APIDef = {
    method: 'POST',
    api: () => `hw/getDefaultTestRecommendation`,
  };

  //<====================================> GET MIDDLE PAGE API <====================================>

  public static GET_CUSTOMER_ALLERGY: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerAllergy',
  };
  public static GET_CUSTOMER_DIAGNOSIS: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerDiagnosis',
  };
  public static GET_CUSTOMER_INSTRUCTION: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerInstruction',
  };
  public static GET_CUSTOMER_MEDICINES: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerMedicines',
  };

  public static GET_CUSTOMER_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerMedicalHistory',
  };

  public static GET_CUSTOMER_SYMPTOMS: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerSymptoms',
  };
  public static GET_CUSTOMER_TEST_RECOMMENDATION: APIDef = {
    method: 'POST',
    api: () => 'hw/getCustomerTestRecommendation',
  };
  // public static ADD_GET_CUSTOMER_VITALS: APIDef = {
  //   method: "POST",
  //   api: () => "hw/getCustomerVitals",
  // };

  // <====================================> SEARCH MIDDLE PAGSEARCH <====================================>

  public static SEARCH_CUSTOMER_ALLERGY: APIDef = {
    method: 'POST',
    api: () => 'hw/searchAllergy',
  };

  public static SEARCH_CUSTOMER_DIAGNOSIS: APIDef = {
    method: 'POST',
    api: () => 'hw/searchDiagnosis',
  };

  public static SEARCH_CUSTOMER_INSTRUCTION: APIDef = {
    method: 'POST',
    api: () => 'hw/searchInstruction',
  };

  public static SEARCH_CUSTOMER_MEDICINE: APIDef = {
    method: 'POST',
    api: () => 'hw/searchMedicines',
  };

  public static SEARCH_CUSTOMER_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => 'hw/searchMedicalHistory',
  };

  public static SEARCH_CUSTOMER_SYMPTOMS: APIDef = {
    method: 'POST',
    api: () => 'hw/searchSymptoms',
  };

  public static SEARCH_CUSTOMER_TEST_RECOMENDATION: APIDef = {
    method: 'POST',
    api: () => 'hw/searchTestRecommendation',
  };

  public static SAVE_NEW_CATEGORIES: APIDef = {
    method: 'POST',
    api: (data) => `hw/${data.id}`,
  };

  // COMMON API CALL FOR ADD/REMOVE/UPDATE

  public static CATEGORIES: APIDef = {
    method: 'POST',
    api: (data) => `hw/${data.id}`,
  };

  public static DOCS_UPLOADED_BY_PATIENT: APIDef = {
    method: 'POST',
    api: () => `hw/docsUploadedByPatient`,
  };

  public static D_RANGE: APIDef = {
    method: 'POST',
    api: () => `hw/drange`,
  };

  public static CONSULTATION_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/Consultation-history`,
  };

  public static TEST_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/Test-history`,
  };

  public static DOCTOR_COMMENTS: APIDef = {
    method: 'POST',
    api: () => 'hw/updateDoctorNotes',
  };

  public static GET_DOCTOR_COMMENTS: APIDef = {
    method: 'POST',
    api: () => 'hw/doctor-notes',
  };

  // <==================================== PASTHISTORY MIDDLE PAGSEARCH ====================================>

  public static GET_SYMPTOMS_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerSymptomsPastHistory`,
  };

  public static GET_ALLERGY_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerAllergyPastHistory`,
  };

  public static GET_INSTRUCTION_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerInstructionPastHistory`,
  };

  public static GET_DIAGNOSIS_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerDiagnosisPastHistory`,
  };

  public static UPDATE_MEDICINES_FORM: APIDef = {
    method: 'POST',
    api: () => `hw/updateCustomerMedicines`,
  };

  public static GET_MEDICINES_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerMedicinesPastHistory`,
  };
  public static GET_MEDICAL_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerMedicalPastHistory`,
  };

  public static GET_TESTRECOMM_HISTORY: APIDef = {
    method: 'POST',
    api: () => `hw/customerTestRecommendationPastHistory`,
  };

  public static LANDING_CALL: APIDef = {
    method: 'POST',
    api: () => `hw/call-landing`,
  };

  public static UPDATE_CALL_LANDING: APIDef = {
    method: 'POST',
    api: () => `hw/updateCallLanding`,
  };

  public static UPDATE_CALL_DISPOSE: APIDef = {
    method: 'POST',
    api: () => `hw/call-dispose`,
  };

  public static GET_FAMILY_DETAILS: APIDef = {
    method: 'POST',
    api: (data) => `hw/family/${data.id}`,
  };

  public static CHECK_PRESCRIPTION_SENT: APIDef = {
    method: 'POST',
    api: () => `hw/checkPrescriptionSent`,
  };

  public static SEARCH_FAMILY_BY_NUMBER: APIDef = {
    method: 'POST',
    api: () => `hw/family/getUserByMobileNumber`,
  };

  public static UPDATE_MEDICINE_DOSAGE_BY_ID: APIDef = {
    method: 'POST',
    api: () => `hw/updateCustomerMedicines`,
  };

  public static GET_MEDICINE_DOSAGE: APIDef = {
    method: 'POST',
    api: () => `hw/getCustomerMedicinesDoses`,
  };

  public static REMOVE_MEDICINE_DOSAGE: APIDef = {
    method: 'POST',
    api: () => `hw/removeCustomerDose`,
  };
  public static REMOVE_ALL_DATA: APIDef = {
    method: 'POST',
    api: () => `hw/remove-all-data`,
  };

  // <==================================== API TO GET DATA FOR PRESCRIPTION AND SEND PRESCRIPTION TO DB ====================================>

  public static GET_CONSULTAION_DATA: APIDef = {
    method: 'POST',
    api: () => `hw/ConsultationReport`,
  };

  public static SEND_PRESCRIPTION_REPORT: APIDef = {
    method: 'POST',
    api: () => `hw/updatePrescriptionStatus`,
  };

  public static CHECK_MANDATORY_FIELDS_ON_PREVIEW: APIDef = {
    method: 'POST',
    api: () => `hw/checkMandatoryFieldsOnPreview`,
  };

  public static MOVE_DASHBOARD: APIDef = {
    method: 'POST',
    api: () => `hw/moveDashboard`,
  };
}

export interface APIDef {
  method: string;
  api: any;
  noNeedAuthToken?: boolean;
}

export interface APIInput {
  id?: string;
}
